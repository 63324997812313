import React from "react";
import "../assets/css/w3.css"
import "../assets/css/Montserrat.css"
import "../assets/css/Lato.css"
import Header from "../components/Header";
import Navbar from "../components/Navbar";
import walkie from "../assets/img/radio.jpg"
import Footer from "../components/Footer";
export default function Home() {
  return(
      <>
        <Navbar/>
        <Header/>
            <div className="w3-content">
                <h1>About us</h1>
                <div className="w3-twothird">

                    <h5 className="w3-padding-32">
                        We have experience in designing radio deployment systems for over 20 years.
                        We are involved with the sales, service and support of Kenwood repeaters and Radios.
                    </h5>
                    <p className="w3-text-grey">
                        Take a look at <a target="_blank" rel="noreferrer" href={"https://www.kenwood.com/india/com/"}>Kenwood's catalog</a> and feel free
                        to contact our sales team to assist in choosing the right radio
                    </p>
                </div>
                <img className={"w3-third w3-center"} alt={"Man holding walkie-talkie"} src={walkie}/>

            </div>
        <Footer/>
      </>
  );
}
