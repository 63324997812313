import React from "react";
import "../assets/css/w3.css"
import Brand from "../assets/img/brand.svg"
import JVCLogo from "../assets/img/jvc_logo.svg"

export default function Header () {
    return(
        <header className="w3-container w3-center" style={{top:0}}>
                <img src={Brand}/> <br/><br/><br/> {/* TODO: fix this <br> garbage*/}

                Authorized Distributor of  <img src={JVCLogo}/>
        </header>
    );
}
